/*  "./vendor/normalize.css");
 "./components/Header/Header.css");
 "./components/Footer/Footer.css");
 "./components/common/Preloader/Preloader.css");
 "./components/common/CollapsingField/CollapsingField.css");
 "./styles.css"); */

@import "./animations.css";

:root {
  --primary-font-family: var(--font-montserrat), Arial, sans-serif, Helvetica;
  --font-h-xl: 500 4.375rem/1.1 var(--font-montserrat);
  --font-h-l: 500 3.0625rem/1.2 var(--font-montserrat);
  --font-h-m: 500 1.75rem/1.3 var(--font-montserrat);
  --font-h-s: 500 1.375rem/1.3 var(--font-montserrat);

  --font-l: 400 1.25rem/1.5 var(--font-montserrat);
  --font-ml: 400 1.125rem/1.3 var(--font-montserrat);
  --font-m: 400 1rem/1.5 var(--font-montserrat);
  --font-s: 400 0.9375rem/1.6 var(--font-montserrat);
  --font-xs: 400 0.875rem/1.6 var(--font-montserrat);

  --primary-bg-color: #191919;
  --secondary-bg-color: #f4f5f6;

  --color-grey: #b5b1b1;
  --color-l-grey: #e7e7e7;
  --color-m-grey: #c7c5c5;
  --color-dark-grey: #323232;
  --color-accent: #bba187;
  --color-visited: #927b66;
  --color-black: #191919;
  --color-white: #ffffff;
  --color-white2: #f0f3f5;
  --color-white3: #f5f5f5;
  --color-error: #d22f2f;
}

/* @media screen and (max-width: 1200px) {
  :root {
    --font-h-xl: 500 3.6rem/1.1 var(--font-montserrat);
    --font-h-l: 500 2.75rem/1.2 var(--font-montserrat);
    --font-h-m: 500 1.5rem/1.3 var(--font-montserrat);
    --font-h-s: 500 1.25rem/1.3 var(--font-montserrat);
    --font-l: 400 1.175rem/1.5 var(--font-montserrat);
    --font-ml: 400 1.08rem/1.3 var(--font-montserrat);
  }
} */

@media screen and (max-width: 1000px) {
  :root {
    --font-h-xl: 500 3.375rem/1.1 var(--font-montserrat);
    --font-h-l: 500 2.5625rem/1.2 var(--font-montserrat);
    --font-h-m: 500 1.35rem/1.3 var(--font-montserrat);
    --font-h-s: 500 1.125rem/1.3 var(--font-montserrat);
    --font-l: 400 1.125rem/1.5 var(--font-montserrat);
    --font-ml: 400 1.06rem/1.3 var(--font-montserrat);
    --font-m: 400 0.95rem/1.5 var(--font-montserrat);
    --font-s: 400 0.9rem/1.6 var(--font-montserrat);
    --font-xs: 400 0.85rem/1.6 var(--font-montserrat);
  }
}

@media screen and (max-width: 800px) {
  :root {
    --font-h-xl: 500 3rem/1.1 var(--font-montserrat);
    --font-h-l: 500 2rem/1.2 var(--font-montserrat);
    --font-h-m: 500 1.25rem/1.3 var(--font-montserrat);
    --font-h-s: 500 1rem/1.3 var(--font-montserrat);
    --font-l: 400 1rem/1.5 var(--font-montserrat);
    --font-ml: 400 1rem/1.3 var(--font-montserrat);
    --font-m: 400 0.9rem/1.5 var(--font-montserrat);
    --font-s: 400 0.95rem/1.6 var(--font-montserrat);
    --font-xs: 400 0.85rem/1.6 var(--font-montserrat);
  }
}

.theme-light {
  background-color: var(--color-white);
}

.theme-dark {
  background-color: var(--color-black);
}

html {
  font-family: var(--font-montserrat);
  scroll-behavior: smooth;
  /* Firefox scrollbar styles*/
  scrollbar-width: thin;
}

@media screen and (min-width: 600px) {
  html {
    scrollbar-color: var(--color-grey) var(--color-dark-grey);
    filter: grayscale(100%);
    transition: filter 1s ease-out;
  }
  html:hover {
    scrollbar-color: var(--color-accent) var(--color-dark-grey);
    filter: none;
  }
}

html.theme-light {
  scrollbar-color: var(--color-accent) var(--color-l-grey);
}

/* WebKit */
body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-track {
  background-color: var(--color-dark-grey);
  border-radius: 8px;
}

.theme-light body::-webkit-scrollbar-track {
  background-color: var(--color-l-grey);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--color-accent);
  border-radius: 8px;
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/* 
.cc--darkmode {
  background-color: var(--color-black);
} */


@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes header {
  0% {
    transform: translateY(-100%);
  }
  70% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes hero-image {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}

@keyframes hero-content {
  0% {
    transform: translate(-50%, -35%);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}

@keyframes hero-overlay {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes project-hero-fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.mainPage .header {
  animation: header ease-out 4s;
}
.mainPage .header {
  animation: header ease-out 4s;
}

.mainPage .hero__overlay {
  opacity: 0;
  animation: hero-overlay 1.5s ease-out 2s forwards;
}

@media screen and (max-width: 600px) {
  .mainPage .header {
    animation: header ease-out 2s;
  }
}
.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: var(--color-black);
  max-width: 100%;
  font-family: var(--primary-font-family);
}

.page.page_light {
  background: var(--color-white3);
}

.page__wrapper .wrapper {
  margin: 240px auto 0;
  padding: 0 46px;
  max-width: 1440px;
}

@media screen and (max-width: 1000px) {
  .page__wrapper .wrapper {
    margin: 180px auto 0;
    padding: 0 30px;
  }
}

@media screen and (max-width: 800px) {
  .page__wrapper .wrapper {
    margin: 150px auto 0;
  }
}

@media screen and (max-width: 600px) {
  .page__wrapper .wrapper {
    margin: 120px auto 0;
    padding: 0 15px;
  }
}

.hero__logo-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hero__logo-icon {
  transform: translate(0%, 0%);
  opacity: 0;
  z-index: 1;
  animation: fade-out 1.1s ease-out forwards;
  height: 60px;
}

.hero__logo-container {
  position: relative;
  z-index: 2;
  width: 100vw;
  height: 100vh;
}


/* html {
  scroll-snap-type: y mandatory;
} 

.page__wrapper  .wrapper { 
  scroll-snap-align: start;
} */

/* @media screen and (min-width: 600px) { */

/* 
.projectPage .hero {
  animation: project-hero-fadein 0.25s ease-out forwards;
} */

.mainPage {
  background: var(--color-black);
  color: var(--color-white);
}

/* .pageTitle__content.fixed {

} */

/* header styles */


.header {
  display: flex;
  position: fixed;
  top: 0;
  z-index: 3;
  transition: top 0.5s ease-out;
  width: 100%;
  font: var(--font-s);
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}

.mainPage .header {
  display: flex;
}
.mainPage .header.header_visible {
  display: flex;
}


.header .modal {
  letter-spacing: normal;
  text-transform: none;
}

.header.hide {
  top: -80px;
}

.header.header_light {
  color: var(--color-white);
}

.header.header_dark {
  color: var(--color-black);
}

.page_light.article .header {
  color: var(--color-white);
}

/* gradient on scroll top */
.header.header_light::before,
.header.header_dark::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  z-index: -1;
  transition: opacity 1s ease-out;
  content: "";
}

.header.header_light::before {
  background-image: linear-gradient(
    rgba(25, 25, 25, 0.8) 50%,
    rgba(25, 25, 25, 0.7),
    rgba(25, 25, 25, 0.6),
    rgba(25, 25, 25, 0)
  );
}

.header.header_dark::before {
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.35),
    rgba(0, 0, 0, 0)
  );
}

.header.header_light.show::before,
.header.header_dark.show::before {
  opacity: 1;
  transition: opacity 1s ease-out;
}

@media screen and (max-width: 600px) {
  .header {
    font: var(--font-m);
  }
}
