/* text appearance animation */

/* usage: add .animation__text-rise class and 
extra div wrapper .animation__wrapper

in-block manipaulation like this 

.***block-name***.animation .animation__text-rise {
  animation-delay: 0.5s;
}
.***block-name***.animation .animation__text-rise .animation__wrapper {
    animation-delay: 0.5s;
} */

.animation__text-rise {
  opacity: 0;
}

.animation .animation__text-rise {
  width: max-content;
  clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 100%);
  overflow: hidden;
  animation: clipPathExpand 1s ease-out forwards;
}

.animation .animation__text-rise .animation__wrapper {
  animation: slideIn 1s ease-out forwards;
}

@keyframes slideIn {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes clipPathExpand {
  to {
    opacity: 1;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
}

/* end */

/* simple text invisible animation */

/* @media screen and (min-width: 600px) { */

.animation__text-invis p {
  opacity: 0;
  transform: translate3d(0px, 30px, -20px);
  will-change: opacity, transform;
}

.animation .animation__text-invis p {
  opacity: 1;
  transform: perspective(1000px) translate3d(0px, 0px, 0px);
  transition:
    transform 0.5s ease-out,
    opacity 0.5s ease-out;
  --delay-step: 0.125s;
}
/* } */

.animation .animation__text-invis p:nth-child(1) {
  transition-delay: calc(var(--delay) + var(--delay-step));
}
.animation .animation__text-invis p:nth-child(2) {
  transition-delay: calc(var(--delay) + 2 * var(--delay-step));
}
.animation .animation__text-invis p:nth-child(3) {
  transition-delay: calc(var(--delay) + 3 * var(--delay-step));
}
.animation .animation__text-invis p:nth-child(4) {
  transition-delay: calc(var(--delay) + 4 * var(--delay-step));
}
.animation .animation__text-invis p:nth-child(5) {
  transition-delay: calc(var(--delay) + 5 * var(--delay-step));
}
.animation .animation__text-invis p:nth-child(6) {
  transition-delay: calc(var(--delay) + 6 * var(--delay-step));
}
.animation .animation__text-invis p:nth-child(7) {
  transition-delay: calc(var(--delay) + 7 * var(--delay-step));
}
.animation .animation__text-invis p:nth-child(8) {
  transition-delay: calc(var(--delay) + 8 * var(--delay-step));
}
.animation .animation__text-invis p:nth-child(9) {
  transition-delay: calc(var(--delay) + 9 * var(--delay-step));
}
.animation .animation__text-invis p:nth-child(10) {
  transition-delay: calc(var(--delay) + 10 * var(--delay-step));
}
.animation .animation__text-invis p:nth-child(11) {
  transition-delay: calc(var(--delay) + 11 * var(--delay-step));
}
.animation .animation__text-invis p:nth-child(12) {
  transition-delay: calc(var(--delay) + 12 * var(--delay-step));
}
.animation .animation__text-invis p:nth-child(13) {
  transition-delay: calc(var(--delay) + 13 * var(--delay-step));
}
.animation .animation__text-invis p:nth-child(14) {
  transition-delay: calc(var(--delay) + 14 * var(--delay-step));
}
.animation .animation__text-invis p:nth-child(15) {
  transition-delay: calc(var(--delay) + 15 * var(--delay-step));
}
.animation .animation__text-invis p:nth-child(16) {
  transition-delay: calc(var(--delay) + 16 * var(--delay-step));
}

/*  */
/* end */

/*  */
/* end */

/*  */
/* end */

/*  */
/* end */

/*  */
/* end */

/*  */
/* end */
